<template>
	<div>
		<div class="p_layout_header p_layout">
			<div class="collapse-btn">
				<img :src="$baseImageUrl + webInfo.web_logo" class="logo_box" />
			</div>
			<div class="logo" style="margin-left: 16px">
				{{ this.$logotitle }}
			</div>
			<div class="layout_header-right">
				<div class="layout_header-user-con">
					<el-dropdown
						class="user-name"
						trigger="click"
						@command="handleCommand"
					>
						<span class="el-dropdown-link">
							{{ userInfo.mobile }}
							<i class="el-icon-caret-bottom"></i>
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item command="resetPaw"
									>修改密码</el-dropdown-item
								>
								<el-dropdown-item divided command="loginout"
									>退出登录</el-dropdown-item
								>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
			</div>
			<el-drawer
				title=""
				:visible.sync="pwd_show"
				direction="rtl"
				size="20%"
			>
				<div style="padding: 0 30px">
					<div class="p_page_title">
						<div class="title_left"></div>
						<div class="title_right">修改登录密码</div>
					</div>
				</div>
				<el-form
					:model="pwdInfo"
					:rules="rules"
					label-width="110px"
					ref="pwdForm"
					style="width: 330px; margin: 0 auto"
				>
					<el-form-item label="账号：" prop="mobile" class="form_box">
						<el-input
							v-model="pwdInfo.mobile"
							placeholder="请输入手机号码"
						></el-input>
					</el-form-item>
					<el-form-item label="图形验证码：" prop="captcha">
						<div class="code_box">
							<el-input
								v-model="pwdInfo.captcha"
								placeholder="图形验证码"
								style="width: calc(100% - 112px)"
							></el-input>
							<img
								:src="codeImg"
								style="width: 110px; height: 34px"
								@click="getCodeImg"
							/>
						</div>
					</el-form-item>
					<el-form-item label="短信验证码：" prop="code">
						<div class="code_box">
							<el-input
								v-model="pwdInfo.code"
								placeholder="短信验证码"
								style="width: calc(100% - 92px)"
							></el-input>
							<el-button
								size="mini"
								class="code_btn"
								:disabled="isDownTime"
								@click="getMoCode"
								>{{ codeText }}
							</el-button>
						</div>
					</el-form-item>

					<el-form-item label="新密码：" prop="passOne">
						<el-input
							type="password"
							placeholder="请输入新密码"
							v-model="pwdInfo.passOne"
						></el-input>
					</el-form-item>

					<el-form-item label="确认密码：" prop="passTwo">
						<el-input
							type="password"
							placeholder="请输入确认密码"
							v-model="pwdInfo.passTwo"
						></el-input>
					</el-form-item>

					<el-form-item>
						<el-button
							:loading="pwdLoading"
							type="primary"
							size="small"
							@click="submitPwd"
							>确定</el-button
						>
					</el-form-item>
				</el-form>
			</el-drawer>
		</div>
		<div class="m_layout">
			<div class="m_layout_header">
				<img :src="$baseImageUrl + webInfo.web_logo" class="logo_img" />
				<div class="user_box">
					您好，{{ userInfo.mobile }}
					<van-icon
						name="wap-nav"
						color="#fff"
						size="28"
						@click="menu_show = !menu_show"
					/>
				</div>
			</div>

			<van-popup
				v-model="menu_show"
				position="right"
				:style="{ width: '22.3rem', height: '100%' }"
			>
				<div class="m_menu_list">
					<template v-for="item in menuList">
						<div :key="item.id" v-if="item.sub && item.sub.length">
							<div class="menu_title one_level">
								{{ item.title }}
							</div>
							<div
								v-for="sub in item.sub"
								:key="sub.id"
								class="menu_title two_level"
								@click="onClickMenu(sub.front_menu)"
								:class="{
									active: activeMenu == sub.front_menu,
								}"
							>
								{{ sub.title }}
							</div>
						</div>
						<div :key="item.id" v-else>
							<div
								class="menu_title one_level"
								:class="{
									active: activeMenu == item.front_menu,
								}"
								@click="onClickMenu(item.front_menu)"
							>
								{{ item.title }}
							</div>
						</div>
					</template>
					<div
						class="one_level login_out_text"
						@click="handleLoginOut"
					>
						退出登录
					</div>
				</div>
			</van-popup>
		</div>
	</div>
</template>
<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import { api_url } from '@/utils/common'

export default {
	props: ['activeMenu'],
	data() {
		let validatePass = (rule, value, callback) => {
			if (!value || value == '') {
				callback(new Error('请确认新密码'))
			} else if (value !== this.pwdInfo.passOne) {
				callback(new Error('新密码和确认密码不一致!请重新输入'))
			} else {
				callback()
			}
		}
		return {
			webInfo: {}, //站点信息
			message: 2,
			pwdInfo: {},
			pwd_show: false,
			rules: {
				code: [
					{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur',
					},
				],
				passOne: [
					{
						required: true,
						message: '请输入新密码',
						trigger: 'blur',
					},
				],
				passTwo: [
					{
						required: true,
						validator: validatePass,
						trigger: 'blur',
					},
				],
			},
			pwdLoading: false,

			isDownTime: false,
			codeText: '获取验证码',
			codeImg: '',

			menu_show: false,
		}
	},
	computed: {
		collapse() {
			return this.$store.state.collapse
		},
		userInfo() {
			return this.$store.state.userInfo
		},
		menuList() {
			return this.$store.state.menuList
		},
	},
	methods: {
		// 侧边栏折叠
		collapseChage() {
			this.$store.commit('handleCollapse', !this.collapse)
		},
		// 用户名下拉菜单选择事件
		handleCommand(command) {
			if (command == 'loginout') {
				this.handleLoginOut()
			} else if (command == 'resetPaw') {
				this.pwd_show = true
				this.getCodeImg()
			}
		},

		handleLoginOut() {
			this.$store.commit('clearTags')
			Cookies.remove('ag_TOKEN')
			sessionStorage.clear()
			this.$router.push('/login')
		},

		//#region 修改登录密码
		async getCodeImg() {
			axios
				.get(api_url + 'api/captcha/captcha', {
					responseType: 'arraybuffer',
				})
				.then((res) => {
					console.log('返回', res)
					// this.key = res.headers.captcha
					return (
						'data:image/jpeg;base64,' +
						btoa(
							new Uint8Array(res.data).reduce(
								(data, byte) =>
									data + String.fromCharCode(byte),
								''
							)
						)
					)
				})
				.then((data) => {
					this.codeImg = data
				})
				.catch((ex) => {
					console.error(ex)
				})
		},

		async getMoCode() {
			if (!this.pwdInfo.mobile) {
				this.$message.error('手机号不能为空')
				return
			}
			if (!this.pwdInfo.captcha) {
				this.$message.error('图片验证码不能为空')
				return
			}
			let res = await this.$http({
				url: `agent/agent_login/sendSms`,
				method: 'post',
				data: {
					mobile: this.pwdInfo.mobile,
					captcha: this.pwdInfo.captcha,
				},
			})
			const { code, msg } = res
			if (code === 200) {
				this.$message.success('发送成功')
				this.fuckTime(60)
			} else {
				this.pwdInfo.code = ''
				this.getCodeImg()
			}
		},
		// 倒计时
		fuckTime(sec) {
			this.isDownTime = true
			let timer = setInterval(() => {
				if (sec < 1) {
					this.codeText = '获取验证码'
					this.isDownTime = false
					clearInterval(timer)
				} else {
					this.codeText = `${sec}秒后重试`
					sec--
				}
			}, 1000)
		},

		submitPwd() {
			this.$refs.pwdForm.validate((vaild) => {
				if (vaild) {
					this.pwdLoading = true
					this.$http
						.post(
							'agent/agentInformation/resetPassword',
							this.pwdInfo
						)
						.then((res) => {
							this.pwdLoading = false
							if (res.code == 200) {
								this.$message.success('修改成功，请重新登录')
								this.handleLoginOut()
							}
						})
				}
			})
		},
		//#endregion

		onClickMenu(url) {
			this.menu_show = false
			this.$router.push(url)
		},
	},
	mounted() {
		this.webInfo = sessionStorage.getItem('webInfo')
			? JSON.parse(sessionStorage.getItem('webInfo'))
			: {}
		if (document.body.clientWidth < 1500) {
			this.collapseChage()
		}
	},
}
</script>
<style scoped lang="less">
.p_layout {
	&.p_layout_header {
		//position: relative;
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		height: 68px;
		font-size: 22px;
		color: #333333;
		background-color: #ffffff;

		.collapse-btn {
			float: left;
			padding: 0 16px;
			cursor: pointer;
			margin-top: 16px;
			//line-height: 68px;
			border-right: 1px solid #e5e5e5;
		}

		.collapse-btn:hover {
			background: var(--tipbgc);
		}

		.logo {
			float: left;
			width: 250px;
			line-height: 68px;
		}

		.layout_header-right {
			float: right;
			padding-right: 50px;
		}

		.layout_header-user-con {
			display: flex;
			height: 68px;
			align-items: center;
		}
	}

	.logo_box {
		width: 36px;
		height: 36px;
	}

	.user-name {
		margin-left: 10px;
	}

	.el-dropdown-link {
		color: #333333;
		cursor: pointer;
		font-size: 16px;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}

	.code_box {
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		display: flex;
		align-items: center;

		/deep/ .el-input__inner {
			border: none;
			padding: 0 0 0 15px;
		}

		button {
			background: #94c5ff;
			border-color: #94c5ff;
			color: #ffffff;
		}

		/deep/ .el-button--mini {
			padding: 7px;
		}
	}
}

.m_layout {
	/deep/ .van-overlay {
		background: rgba(0, 0, 0, 0.5);
	}

	.m_layout_header {
		position: fixed;
		top: 0;
		left: 0;
		height: 6.4rem;
		width: calc(100% - 3.2rem);
		padding: 0 1.6rem;
		background: #4c64fe;
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 2010;

		.logo_img {
			width: 3.9rem;
			height: 3.9rem;
		}

		.user_box {
			color: #ffffff;
			font-size: 1.4rem;
			font-weight: 300;
			display: flex;
			align-items: center;

			i {
				margin-left: 2.4rem;
			}
		}
	}

	.m_menu_list {
		margin-top: 8rem;
		padding-left: 2.4rem;

		.menu_title {
			margin-bottom: 1.6rem;

			&.active {
				color: #4c64fe;
			}
		}

		.one_level {
			font-size: 1.4rem;
			font-family: 'PingFang SC';
			font-weight: 400;
			color: #333333;
			margin-top: 2.4rem;
		}

		.two_level {
			height: 1.7rem;
			font-size: 1.2rem;
			font-family: 'PingFang SC';
			font-weight: 300;
			color: #666666;
		}

		.login_out_text {
			margin-top: 6rem;
		}
	}
}
</style>
